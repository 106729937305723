import React from "react";
import "./home.css";
import play from "../../assets/images/roundPlay.svg";
import lightBulb from "../../assets/images/lightBulb.svg";
import pmiSlideImg from "../../assets/images/pmiSlide.png";
import nineSlideImg from "../../assets/images/9mobileSlide.png";
import unitySlideImg from "../../assets/images/unitySlide.png";
import microsoftSlideImg from "../../assets/images/microsoftSlide.png";
import oracleSlideImg from "../../assets/images/oracleSlideImg.svg";
import euSlideImg from "../../assets/images/euSlideLogo.svg";
import strategicCallIcon from "../../assets/images/strategicCall.svg";
import researchIcon from "../../assets/images/research.svg";
import buildIcon from "../../assets/images/build.svg";
import launch from "../../assets/images/launch.svg";
import yellowRing from "../../assets/images/yellowRing.svg";
import yellowTriangle from "../../assets/images/yellowTri.svg";
import webDevIcon from "../../assets/images/webDevIcon.svg";
import digitalIcon from "../../assets/images/digitalIcon.svg";
import productIcon from "../../assets/images/projectIcon.svg";
import IOTIcon from "../../assets/images/iotIcon.svg";
import cyberIcon from "../../assets/images/cyberIcon.svg";
import dataIcon from "../../assets/images/dataIcon.svg";
import infraIcon from "../../assets/images/infraIcon.svg";
import profIcon from "../../assets/images/profIcon.svg";
import animatedLogo from "../../assets/makinsight-draw.svg";
import contactGirl from "../../assets/images/contactGirl.png";
import contactGirl2 from "../../assets/images/contactGirl2.png";
import IBMSlideImg from "../../assets/images/IBMSlideImg.png";

const Home = (props) => {
  window.scrollTo(0, 0);

  const partners = [
    { logo: pmiSlideImg, name: "pmi" },
    { logo: nineSlideImg, name: "9mobile" },
    { logo: unitySlideImg, name: "unity" },
    { logo: microsoftSlideImg, name: "microsoft" },
    { logo: oracleSlideImg, name: "oracle" },
    { logo: euSlideImg, name: "European Union" },
    { logo: IBMSlideImg, name: "IBM" },
  ];

  const wwdData = [
    {
      icon: lightBulb,
      title: "Bespoke software Development",
      for: "For SMEs, startups and large enterprises",
    },
    {
      icon: webDevIcon,
      title: "eCommerce & Website Development",
      for: "For SMEs, startups and large enterprises",
    },
    {
      icon: digitalIcon,
      title: "Digital Transformation",
      for: "Large enterprises",
    },
    {
      icon: productIcon,
      title: "Product Marketing",
      for: "SMEs and Large enterprises",
    },
    {
      icon: IOTIcon,
      title: " IoT Development",
      for: "For funded startups and large enterprises",
    },
    {
      icon: cyberIcon,
      title: "Cyber Security",
      for: "For funded startups and large enterprises",
    },
    {
      icon: dataIcon,
      title: "Data Analysis & Insight",
      for: "For funded startups and large enterprises",
    },
    {
      icon: infraIcon,
      title: "Infrastructural Development",
      for: "For funded startups and large enterprises",
    },
    {
      icon: profIcon,
      title: "Professional & Support Services",
      for: "NGOs, Government and Corporate Enterprises",
    },
  ];

  window.$(document).ready(function () {
    window
      .$(".mak-partner-slide")
      .not(".slick-initialized")
      .slick({
        centerMode: true,
        arrows: false,
        centerPadding: "60px",
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: "40px",
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: "40px",
              slidesToShow: 1,
            },
          },
        ],
      });

    window
      .$(".wwd-sm")
      .not(".slick-initialized")
      .slick({
        // dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  });

  return (
    <React.Fragment>
      {/* HOME PAGE BANNER */}
      <div className="mak-home">
        <section className="row mx-0 mak-home-banner">
          <article className="col-lg-7 col-md-7 pl-0 d-flex align-items-center">
            <div>
              <h1 className="mak-banner-quote">
                Accelerate Business Value
                <br className="no-br" /> with a Reliable{" "}
                <b>Digital Footprint</b>
              </h1>
              <p className="mak-banner-subText">
                We are a team of data-driven professionals digitizing business
                processes for market repositioning and advantage.
              </p>
              <button className="learn-btn">Learn more </button>
            </div>
          </article>
          <article className="col-lg-5 col-md-5 pr-0 hidden d-flex align-items-center justify-content-center">
            <img
              src={animatedLogo}
              alt="logo animated"
              className="animated-logo"
            />
          </article>
        </section>
      </div>
      {/* END OF HOME PAGE BANNER */}

      {/* OUR STORY */}
      <section className="row mx-0 mak-story">
        <article className="col-lg-5 col-md-5 pl-0 mak-story-bg">
          <img src={play} alt="round play" className="mak-story-play" />
        </article>
        <article className="col-lg-7 col-md-7 pr-0 pl-0">
          <div className="mak-story-txt">
            <small>OUR STORY</small>
            <h3>Your Perfect and Experienced Technology Growth Team</h3>
            <p>
              We are building value for today’s business' digital transformation
              journey. On that note, we work as part of your team to share in
              your business vision while we make your enterprise growth our core
              priority. We take your technology goals and ideas through an agile
              development process and ensure all customers and users touch
              points are captured.
            </p>
            {/* <button className="see-more-btn">See more about us</button> */}
          </div>
        </article>
      </section>
      {/* END OF OUR STORY */}

      {/*  WHAT WE DO */}
      <section className="mak-wwd">
        <small>Our Services</small>
        <h2 className="mak-home-titles">What We Do</h2>

        <article className="row mx-0 mak-wwd-college wwd-lg">
          {wwdData.map((wwd, index) => {
            return (
              <section className="col-lg-4 col-md-6 col-sm-6" key={index}>
                <div className="mak-wwd-item d-flex flex-column justify-content-between">
                  <img
                    src={wwd.icon}
                    alt="illustration of one of the thing we do"
                    style={{ width: 63, height: 67 }}
                  />
                  <h3>{wwd.title}</h3>
                  <p>{wwd.for}</p>
                  <button className="know-btn">Know More</button>
                </div>
              </section>
            );
          })}
        </article>

        <article className="row mx-0 mak-wwd-college wwd-sm">
          {wwdData.map((wwd, index) => {
            return (
              <section className="col-lg-4 col-md-6 col-sm-6">
                <div className="mak-wwd-item d-flex flex-column justify-content-between">
                  <img
                    src={wwd.icon}
                    alt="illustration of one of the thing we do"
                    style={{ width: 63, height: 67 }}
                  />
                  <h3>{wwd.title}</h3>
                  <p>{wwd.for}</p>
                  <button className="know-btn">Know More</button>
                </div>
              </section>
            );
          })}
        </article>
      </section>
      {/* END OF WHAT WE DO */}

      {/* OUR WORK PROCESS */}
      <section className="mak-owp">
        <article className="mak-owp-titles">
          <h2 className="mak-home-titles mb-0">Our Work Process </h2>
          <p className="mak-home-subtitles">
            Our approach for effective collaboration with you.{" "}
          </p>
        </article>

        <article className="mak-process-grid row mx-0">
          <div className="mak-process-grid-item col-lg-4 col-md-4 col-sm-12 px-0">
            <div className="text-center">
              <img
                src={strategicCallIcon}
                alt="A strategic conference call with you "
              />
            </div>
            <div className="process-line">
              <img src={yellowRing} alt="yellow ring" className="y-ring" />
              <img
                src={yellowTriangle}
                alt="yellow triangle"
                className="y-triangle"
              />
              <hr />
            </div>
            <p>A strategic conference call with you </p>
          </div>

          <div className="mak-process-grid-item col-lg-2 col-md-2 col-sm-12 px-0">
            <div className="text-center">
              <img
                src={researchIcon}
                alt="Research, Strategise 
                and Plan"
              />
            </div>
            <div className="process-line">
              <img src={yellowRing} alt="yellow ring" className="y-ring" />
              <img
                src={yellowTriangle}
                alt="yellow triangle"
                className="y-triangle"
              />
              <hr />
            </div>
            <p>Research, Strategise and Plan</p>
          </div>

          <div className="mak-process-grid-item col-lg-2 col-md-2 col-sm-12 px-0">
            <div className="text-center">
              <img
                src={buildIcon}
                alt="Build, Test and 
              Improve"
              />
            </div>
            <div className="process-line">
              <img src={yellowRing} alt="yellow ring" className="y-ring" />
              <img
                src={yellowTriangle}
                alt="yellow triangle"
                className="y-triangle"
              />
              <hr />
            </div>
            <p>Build, Test and Improve</p>
          </div>

          <div className="mak-process-grid-item col-lg-4 col-md-4 col-sm-12 px-0">
            <div className="text-center">
              <img
                src={launch}
                alt="Final Launch + A Month of 
              Free Post-Launch Support"
              />
            </div>
            <div className="process-line">
              <img src={yellowRing} alt="yellow ring" className="y-ring" />
              <hr />
            </div>
            <p>Final Launch + A Month of Free Post-Launch Support</p>
          </div>
        </article>
      </section>
      {/* END OF WORK PROCESS */}

      {/* OUR PARTNERS */}

      <section className="mak-partners">
        <h2 className="mak-home-titles">Our Partners & Clients</h2>

        <div className="mak-partner-slide">
          {partners.map((partner, index) => {
            return (
              <section
                key={index}
                className="d-flex justify-content-center align-items-center partner-space"
              >
                <img
                  src={partner.logo}
                  alt={`our partner ${partner.name}`}
                  className="partner-logo"
                />
              </section>
            );
          })}
        </div>
      </section>
      {/* END OF OUR PARTNERS */}

      {/* OUR WORKS */}
      {/* <section className="mak-works">
        <h2 className="mak-home-titles pb-4">
          Our Works <img src={worksEmoji} alt="emoji for our works" />
        </h2>
        <article className="d-flex justify-content-center">
          <section className="row mx-0 mak-works-box d-flex justify-content-center">
            {worksData.map((work, index) => {
              return (
                <div
                  className="col-lg-4 col-md-4 col-xs-12  mak-works-box-layout d-flex flex-column justify-content-between"
                  style={{
                    background: `url('${work.image}')`,
                    backgroundPosition: "center bottom",
                    backgroundRepeat: "no-repeat",
                    height: 290,
                    width: "254px",
                  }}
                >
                  <h4>{work.title}</h4>
                  <div>
                    <img src={work.logo} alt="logo of work" />
                  </div>
                  <p>{work.category}</p>
                </div>
              );
            })}
            <div className="col-12 pt-4 d-flex justify-content-center">
              <button className="see-more-btn">See more works</button>
            </div>
          </section>
        </article>
      </section> */}
      {/* END OF OUR WORKS */}

      {/* CONTACT US */}
      {/* <section className="mak-contact">
        <h2>
          CONTACT US
        </h2>
        <h3>
          Build your tech
          <br /> support team with us.
        </h3>
        <form>
          <div>
            <input type="text" placeholder="Full Name" />
          </div>
          <div>
            <input type="email" placeholder="E-mail" />
          </div>
          <div>
            <input type="text" placeholder="Give us a brief description" />
          </div>

          <button className="learn-btn mt-3">Send</button>
        </form>
      </section> */}

      {/* <section className="row mx-0 contact-mak d-flex align-items-end">
        <div className="col-lg-6 col-md-6 contact-img-shift">
          <img src={contactGirl} alt="contact girl" className="contact-girl"/>
        </div>
        <div className="col-lg-6 col-md-6 contact-txt-shift">
          <div>
              <h2>LET’S BUILD SOMETHING </h2>
              <div className="text-center">
              <button>GET IN TOUCH</button>
              </div>
          </div>
        </div>
      </section> */}

      <section className="row mx-0 contact-mak d-flex align-items-end">
        <div className="col-lg-5 col-md-5 contact-img-shift">
          <img src={contactGirl2} alt="contact girl" className="contact_girl" />
        </div>
        <div className="col-lg-7 col-md-7 contact-txt-shift">
          <div className="contact-section-content">
            <h2 className="contact-header mb-0">LET’S BUILD SOMETHING</h2>
            <div className="text-center">
              <button
                className="btn btn-default contact-section-button"
                onClick={() => {
                  props.history.push("/contact");
                }}
              >
                GET IN TOUCH
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* END OF CONTACT */}
    </React.Fragment>
  );
};

export default Home;
