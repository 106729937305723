import React from "react";
import "./contact.css";
import contactPageVec from "../../assets/images/contactPage.png";

const contact = () => {
  window.scrollTo(0,0)
  return (
    <main>
      <div className="contact-page">
        <section className="row mx-0 contact-banner">
          <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center contact-banner-items contact-banner-vector">
            <img src={contactPageVec} alt="contact vector" />
          </div>
          <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-end contact-banner-items">
            <div className="contact-banner-content">
              <h1>Get our team involved.</h1>
              <p>
                We are always prepared to meet your technology needs leveraging
                the most innovative and enterprising development and deployment
                strategies.
              </p>
            </div>
          </div>
        </section>
      </div>

      <section className="contact-form">
        <h3>Send us a quick note and we will be in touch</h3>
        <form>
          <div className="row mx-0">
            <div className="col-lg-6 col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Full Name*"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Phone*"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <input
                type="email"
                className="form-control"
                placeholder="E-mail*"
              />
            </div>
            <div className="col-12">
              <textarea
                rows={5}
                placeholder="Your Enquiry*"
                className="form-control"
              />
            </div>
            <div className="col-12">
              <button>Submit</button>
            </div>
          </div>
        </form>
      </section>
    </main>
  );
};

export default contact;
