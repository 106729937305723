import React from "react";
import "./about.css";
import whoWeAreVector from "../../assets/images/about//whoWeAreVec.svg";
import scrollOver1 from "../../assets/images/about/scrollOver1.png";
import scrollOver2 from "../../assets/images/about/scrollOver2.png";
import socialVal from "../../assets/images/about/valueSocial.svg";
import qualityVal from "../../assets/images/about/valueQuality.svg";
import collabVal from "../../assets/images/about/valueCollab.svg";
import whyUsVec from "../../assets/images/about/whyChooseUsVec.png";
import realTime from "../../assets/images/about/real-time-support.png";
import dataDriven from '../../assets/images/about/dataDriven.png';
import pricing from '../../assets/images/about/CompetitivePricing.png';
import bestPractices from '../../assets/images/about/bestPractices.png';
import leadingTech from '../../assets/images/about/leadingTech.png';
import flexibleDev from "../../assets/images/about/flexibleDev.png";

const About = () => {
  return (
    <div>
      <article className="banner_container">
        <section className="aboutBanner">
          <h1>About Us</h1>
          <div></div>
        </section>
      </article>

      <article className="row mx-0" id="whoWeAre">
        <section className="col-lg-4 col-md-4">
          <div className="who-we-are-image d-flex align-items-center justify-content-center">
            <img src={whoWeAreVector} alt="who we are vector" />
          </div>
        </section>
        <section className="col-lg-8 col-md-8">
          <div className="who-we-are-text d-flex align-items-center">
            <div>
              <h2 className="about_title">Who we are</h2>
              <p className="about_txt">
                We are a team of designers, developers, product and marketing
                strategists who are passionate about delivering innovative
                solutions that leave a lasting impact on businesses and people.
                At the centre of our operations, we place a priority on serving
                the needs of the users while also delivering value that
                amplifies our clients' business vision.
              </p>

              <p className="about_txt">
                With the future of technology explicitly giving us a glimpse of
                how the work environment will be transformed in the nearest
                years to come, we are poised to help organisations leverage the
                opportunities that are available today to prepare for the
                imminent future of technology and its lasting impact on
                businesses across the world. At MakinInsight, we keep ahead of
                trends in the business and technology industries and identify
                gaps that our clients and partners can leverage to drive
                competitive advantage and business efficiency.{" "}
              </p>
            </div>
          </div>
        </section>
      </article>

      <section className="scroll-over1"></section>

      <article className="row mx-0" id="our-values">
        <div className="col-12 pb-4">
          <h1 className="about_title">Our Values</h1>
        </div>
        <section className="col-lg-4 col-md-4">
          <div className="about-values">
            <div className="text-center">
              <img src={socialVal} alt="social value icon" />
              <h3>Social impact</h3>
            </div>
            <p>
              Building enterprises that redefines how businesses are done and
              how the economy progresses are courses we cherish and dedicate our
              time too. It our commitment to ensure our ventures solve some of
              the numerous problems we have in our community using technology
            </p>
          </div>
        </section>
        <section className="col-lg-4 col-md-4">
          <div className="about-values">
            <div className="text-center">
              <img src={collabVal} alt="collabration value icon" />
              <h3>Collaboration & Partnership</h3>
            </div>
            <p>
              Our team thrives on the ability to collaborate across all teams
              and ensure silos are not created or creativity is not inhibited by
              stringent company bureaucracy. This, we translate to our
              relationship with our clients. We are not a company to be hired to
              deliver; we are your tech team and we grow with you to drive
              success blueprint for your business.
            </p>
          </div>
        </section>
        <section className="col-lg-4 col-md-4">
          <div className="about-values">
            <div className="text-center">
            <img src={qualityVal} alt="quality value icon" />
              <h3>Quality</h3>
            </div>
            <p>
              We want to always exceed your expectations, in doing that, we
              regard every project or product as a test of our expertise,
              experience, promises and values. Therefore, delivering excellent
              project to time and budget is of utmost priority to our success
              stories.
            </p>
          </div>
        </section>
      </article>

      <section className="scroll-over2"></section>

      <article className="row mx-0" id="why-choose-us">
        <section className="col-lg-4 col-md-4 d-flex align-items-center justify-content-center">
          <img src={whyUsVec} alt="why choose us" className="whyUsImg" />
        </section>
        <section className="col-lg-8 col-md-8 d-flex align-items-center">
          <article className="row mx-0">
            <div className="col-12">
              <h2 className="about_title">Why Choose Us</h2>
              <p className="about_txt">
                We offer digital solutions that are reliable, excellent and
                result-driven. We leverage the agile principles that help us
                keep in mind users, stakeholders, growth and excellent delivery.
              </p>
            </div>
              <div className="col-lg-4 col-md-6 text-center why-items d-flex flex-column justify-content-between py-4">
                <div className="text-center">
                <img src={realTime} alt="real time icon"/>
                </div>
                <p>Real-time support</p>
              </div>
              <div className="col-lg-4 col-md-6 text-center why-items d-flex flex-column justify-content-between py-4">
                <div className="text-center">
                <img src={dataDriven} alt="real time icon"/>
                </div>
                <p>Data-driven solutions</p>
              </div>
              <div className="col-lg-4 col-md-6 text-center why-items d-flex flex-column justify-content-between py-4">
                <div className="text-center">
                <img src={pricing} alt="real time icon"/>
                </div>
                <p>Competitive pricing</p>
              </div>
              <div className="col-lg-4 col-md-6 text-center why-items d-flex flex-column justify-content-between py-4">
                <div className="text-center">
                <img src={bestPractices} alt="real time icon"/>
                </div>
                <p>Best practices</p>
              </div>
              <div className="col-lg-4 col-md-6 text-center why-items d-flex flex-column justify-content-between py-4">
                <div className="text-center">
                <img src={leadingTech} alt="real time icon"/>
                </div>
                <p>Leading technology</p>
              </div>
              <div className="col-lg-4 col-md-6 text-center why-items d-flex flex-column justify-content-between py-4">
              <div className="text-center">
                <img src={flexibleDev} alt="real time icon"/>
              </div>
                <p>Flexible development</p>
              </div>
          </article>
        </section>
      </article>
    </div>
  );
};

export default About;
