import React,{useEffect} from 'react';
import './header.css'
import headerLogo from '../../assets/images/makHeaderLogo.svg';
import {withRouter} from 'react-router-dom'
const Header = (props) => {
  const {open, setOpen} = props

    /**
     * Function to change background of the header when you start scrolling
     */
    const listenToScrollEvent = () => {
        document.addEventListener("scroll", () => {
          requestAnimationFrame(() => {
            var scrollTop = window.pageYOffset;
            let nav = document.querySelector("#mak-header");
            if (scrollTop < 50) {
              nav.style.background = "";
            } else {
              nav.style.background = "linear-gradient(0deg,rgba(255,255,255, 1),rgba(255,255,255, 1))"
            }
          });
        });
      };

/**
 * Function to route to a specified path
 * @param {String} path the route you want to go to
 */
      const routeTo =(path)=>{
          props.history.push(path);
          setOpen(false);
      }


      console.log(props.location)

      useEffect(() => {
        listenToScrollEvent();
      }, []);


      let {pathname} = props.location

    return (
        <nav className="mak-header d-flex justify-content-between align-items-center" id="mak-header">
          <div className="d-flex align-content-center" onClick={()=>{routeTo('/')}}>
           <img src={headerLogo} alt="header logo"/>
           <p className="mb-0 ml-2 mak-header-name">MAKINSIGHT</p>
          </div>
           <ul className="list-inline list_nav" style={{flexBasis: "70%", textAlign: "end"}}>
               <li className={`${pathname === "/"?"mak-nav-active ":""} list-inline-item`} onClick={()=>{routeTo('/')}}>Home</li>
               <li className={`${pathname === "/services"?"mak-nav-active ":""} list-inline-item`}  onClick={()=>{routeTo('/services')}}>Services</li>
               <li className={`${pathname === "/about"?"mak-nav-active ":""} list-inline-item`}  onClick={()=>{routeTo('/about')}}>About</li>
               <li className={`${pathname === "/contact"?"mak-nav-active ":""} list-inline-item`}  onClick={()=>{routeTo('/contact')}}>Contact</li>
           </ul>
           <section className="hams" onClick={()=>{setOpen(!open)}}>
               <div className={open ? "close_top" : "open_top"}></div>
               <div className={open ? "close_middle" : "open_middle"}></div>
               <div className={open ? "close_bottom" : "open_bottom"}></div>
           </section>

           <section className={open?"mak-side-nav": "no-side-bar"}>
           <ul className="list-unstyled">
               <li onClick={()=>{routeTo('/')}}>Home</li>
               <li onClick={()=>{routeTo('/services')}}>Services</li>
               <li onClick={()=>{routeTo('/about')}}>About</li>
               <li onClick={()=>{routeTo('/contact')}}>Contact</li>
           </ul>
           </section>
        </nav>
    )
}

export default withRouter(Header);
