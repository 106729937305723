import React from "react";
import "./footer.css";
import makLogoFooter from "../../assets/images/makFooterLogo.svg";
import headerLogo from "../../assets/images/makHeaderLogo.svg";

const Footer = () => {
  return (
    <div className="mak-footer">
      <section className="row mx-0">
        <article className="col-lg-4 col-md-4">
          {/* <h3>Makinsight</h3> */}
          <h3>
            <div className="d-flex align-content-center">
              <img src={headerLogo} alt="header logo" />
              <p className="mb-0 ml-2 mak-header-name">MAKINSIGHT</p>
            </div>
          </h3>
          <p className="get_info">
            Get informed insights for your next
            <br /> tech-driven decision
          </p>
          <form>
            <input type="email" className="form-control curved-input" />
            <button className="know-btn my-3 px-0">Subscribe</button>
          </form>
        </article>
        <article className="col-lg-3 col-md-4">
          <h4>Services</h4>
          <ul className="list-unstyled">
            <li>Software development</li>
            <li>Website Development</li>
            <li>Product Marketing</li>
            <li>IoT Development</li>
            <li>Cyber Security</li>
          </ul>
        </article>
        <article className="col-lg-2 col-md-4">
          <h4>About</h4>
          <ul className="list-unstyled">
            <li>Contact Us</li>
            <li>Projects or portfolio</li>
            <li>Blog</li>
            <li>Privacy Policy</li>
          </ul>
        </article>
        <article className="col-lg-3 col-md-12">
          <h4>Contact</h4>
          <address>
            1 Kola Adeyina Close Off Jerry Iribiade Street Lekki, Lagos.
            Nigeria.
          </address>
          <address>
            Unit 6 Vickers Business Centre, Priestley Road Basingstoke, RG24
            9NP. England.
          </address>

          <address>30 Mavisbank Gardens Glasgow, G51 1HG. Scotland.</address>
          <a href="mailto: Hello@makinsight.com">
            Email: Hello@makinsight.com
          </a>
        </article>
      </section>
      <p className="mb-0 mak-copy" style={{ fontStyle: "italic" }}>
        &copy; 2020 Makinsight - all rights reserved
      </p>
    </div>
  );
};

export default Footer;
