import React from "react";
import "./ourServices.css";
import Emerging from "../../assets/images/ourServices/emerging.svg";
import Infrastructure from "../../assets/images/ourServices/infrastructure.svg";
import DataAnalysis from "../../assets/images/ourServices/dataAnalysis.svg";
import Cyber from "../../assets/images/ourServices/cyber.svg";
import SoftwareDev from "../../assets/images/ourServices/softwareDev.svg";
import Digital from "../../assets/images/ourServices/digital.svg";
import Product from "../../assets/images/ourServices/productMarket.svg";
import ring from "../../assets/images/ourServices/smallRing1.svg";
import ring1 from "../../assets/images/ourServices/smallRing2.svg";
import ring2 from "../../assets/images/ourServices/smallRing3.svg";
import ring3 from "../../assets/images/ourServices/smallRing4.svg";
import ring4 from "../../assets/images/ourServices/smallRing5.svg";
import ring5 from "../../assets/images/ourServices/smallRing6.svg";
import ring6 from "../../assets/images/ourServices/smallRing7.svg";

import webApp from "../../assets/images/ourServices/webApp.svg";
import eCommerce from "../../assets/images/ourServices/eCommerce.svg";
import Consultancy from "../../assets/images/ourServices/consultancy.svg";
import GovProj from "../../assets/images/ourServices/govProj.svg";
import notForProfit from "../../assets/images/ourServices/notForProfit.svg";
import security from "../../assets/images/ourServices/security.svg";
import finance from "../../assets/images/ourServices/finance.png";
import communication from "../../assets/images/ourServices/communication.png";
import logistics from "../../assets/images/ourServices/logistics.png";
import eCom from "../../assets/images/ourServices/ecommerce.png";
import travel from "../../assets/images/ourServices/travel.png";
import serviceVector from '../../assets/images/ourServices/serviceVector.svg'
import spiral from '../../assets/images/ourServices/spiral.svg';
import oox from '../../assets/images/ourServices/oox.svg';

const OurServices = () => {
  return (
    <div>
      <section id="service-top-banner" className="row mx-0 service-relative">
        <div className="col-lg-7 col-md-12 d-flex align-items-center">
          <article>
            <h1 className="services-titles text-white">
              We work with startups, SMEs,
              <br /> established business or large enterprises
            </h1>
            <p>
              Our client base is a mixed grill of businesses who are looking to
              solve internal and users problems with data-driven technology
              solutions that drive quality business impact and growth. In
              delivering our promises to you, we work to grow with you in
              driving your business goals leveraging the most advanced tools and
              our experience working with businesses across all industries.{" "}
            </p>
          </article>
        </div>


        <div className="col-lg-5 col-md-5 d-flex align-items-center hide-vector">
          <div>
            <img src={serviceVector} alt="service banner illustration" className="service-vector"/>
          </div>
          </div>


        <img src={spiral} style={{position: "absolute", bottom: "-170px", right: 0}}/>

        
      </section>

      <section className="row mx-0 service-relative">
      <div className="col-12 service-subtitle py-5">
          <h3>OUR SERVICES</h3>
          <div></div>
        </div>
        <img src={oox} className="oox" alt="aesthetics" style={{position: "absolute", top: 50, left: 300}}/>
      </section>

      {/* SERVICE WITH IMAGE TO THE RIGHT */}
      <section className="row mx-0 pb-5 services-items">
        <div className="col-lg-8 col-md-8 px-0">
          <img src={ring} alt="ring design" className="mb-4 colored-ring" />
          <img
            alt="it will be here"
            src={Emerging}
            className="alter-service-image"
          />
          <h2 className="services-titles">Emerging Technologies</h2>
          <p className="services-desc">
            With a wide range of intelligent devices connected online, the
            importance of leveraging the data they generate to create an
            improved real time customer experience can not be over emphasised.
            At Mak Insight, we harness the data from your devices connect them
            to your business processes to enable you identify new revenue
            streams with direct impact to your customer satisfaction
          </p>
        </div>

        <div className="col-lg-4 col-md-4 px-0">
          <section className="services-image services-image-r service-image-switch">
            <img alt="emerging tech illustration" src={Emerging} />
          </section>
        </div>
      </section>
      {/*END OF SERVICE WITH IMAGE TO THE RIGHT */}

      {/* SERVICE WITH IMAGE TO THE LEFT */}
      <section className="row mx-0 pb-5 services-items">
        <div className="col-lg-4 col-md-4 px-0">
          <section className="services-image services-image-l">
            <img
              alt="Infrastructure deployment illustration"
              src={Infrastructure}
            />
          </section>
        </div>
        <div className="col-lg-8 col-md-8 px-0 service-shift">
          <img src={ring1} alt="ring design" className="mb-4 colored-ring" />
          <h2 className="services-titles">Infrastructure Deployment</h2>
          <p className="services-desc" style={{ width: "100%" }}>
            Regardless of the topography of your infrastructure, we specialise
            in the procurement, installation and maintenance of your
            infrastructure needs.
          </p>
        </div>
      </section>
      {/*END SERVICE WITH IMAGE TO THE LEFT */}

      {/* SERVICE WITH IMAGE TO THE RIGHT */}
      <section className="row mx-0 pb-5 services-items">
        <div className="col-lg-8 col-md-8 px-0">
          <img src={ring2} alt="ring design" className="mb-4 colored-ring" />
          <img
            alt="Data Analysis & Insight illustration"
            src={DataAnalysis}
            className="alter-service-image"
          />
          <h2 className="services-titles">Data Analysis & Insight</h2>
          <p className="services-desc">
            At Mak Insight, we are poised with a passion to help businesses
            exploit new areas of opportunity by providing revolutionary business
            insights from data via predictive modelling, data mining and
            intelligence reporting.
          </p>
        </div>

        <div className="col-lg-4 col-md-4 px-0">
          <section className="services-image services-image-r service-image-switch">
            <img
              alt="Data Analysis & Insight illustration"
              src={DataAnalysis}
            />
          </section>
        </div>
      </section>
      {/*END OF SERVICE WITH IMAGE TO THE RIGHT */}

      {/* SERVICE WITH IMAGE TO THE LEFT */}
      <section className="row mx-0 pb-5 services-items service-relative">
        <div className="col-lg-4 col-md-4 px-0">
          <section className="services-image services-image-l">
            <img alt="Cyber Security illustration" src={Cyber} />
          </section>
        </div>
        <div className="col-lg-8 col-md-8 px-0 service-shift">
          <img src={ring3} alt="ring design" className="mb-4 colored-ring" />
          <h2 className="services-titles">Cyber Security</h2>
          <p className="services-desc" style={{ width: "100%" }}>
            As organisations become increasingly digital, cyber threats are
            imminent. Ensuring the cyber criminals are kept at bay is a key
            agenda across boardroom conversations. We help define, design and
            implement your cyber security strategy.
          </p>
        </div>
        <img src={oox} className="oox" alt="aesthetics" style={{position: "absolute", right: 40, top: "50px"}} />
      </section>
      {/*END SERVICE WITH IMAGE TO THE LEFT */}

      {/* SERVICE WITH IMAGE TO THE RIGHT */}
      <section className="row mx-0 pb-5 services-items">
        <div className="col-lg-8 col-md-8 px-0">
          <img src={ring4} alt="ring design" className="mb-4 colored-ring" />
          <img
            alt="Software Development illustration"
            src={SoftwareDev}
            className="alter-service-image"
          />
          <h2 className="services-titles">Software Development</h2>
          <p className="services-desc">
            We build bespoke software solutions that enables organisations focus
            on their true value proposition without the burden of managing
            software solutions
          </p>
        </div>

        <div className="col-lg-4 col-md-4 px-0">
          <section className="services-image services-image-r service-image-switch">
            <img alt="Software Development illustration" src={SoftwareDev} />
          </section>
        </div>
      </section>
      {/*END OF SERVICE WITH IMAGE TO THE RIGHT */}

      {/* SERVICE WITH IMAGE TO THE LEFT */}
      <section className="row mx-0 pb-5 services-items service-relative">
        <img src={oox} className="oox" alt="aesthetics" style={{position: "absolute", top:"-100px", left: "30px", transform: "rotateZ(180deg)"}}/>
        <div className="col-lg-4 col-md-4 px-0">
          <section className="services-image services-image-l">
            <img alt="Digital Transformation illustration" src={Digital} />
          </section>
        </div>
        <div className="col-lg-8 col-md-8 px-0 service-shift">
          <img src={ring5} alt="ring design" className="mb-4 colored-ring" />
          <h2 className="services-titles">Digital Transformation</h2>
          <p className="services-desc" style={{ width: "100%" }}>
            As organisations grow, there's a tendency to hoard processes and
            systems that were once useful but are now no longer fit for purpose.
            Our digital transformation experts work side by side with
            organisations to identify, redesign and refresh your existing
            assets. Allowing you to emerge into a whole new digital world where
            efficiency is the order of the day.
          </p>
        </div>
      </section>
      {/*END SERVICE WITH IMAGE TO THE LEFT */}

      {/* SERVICE WITH IMAGE TO THE RIGHT */}
      <section className="row mx-0 pb-5 services-items service-relative">
        <div className="col-lg-8 col-md-8 px-0">
          <img src={ring6} alt="ring design" className="mb-4 colored-ring" />
          <img
            alt="Product marketing illustration"
            src={Product}
            className="alter-service-image"
          />
          <h2 className="services-titles">Product Marketing</h2>
          <p className="services-desc">
            We leverage data and insights to successfully drive the entry of
            impactful products into the market through strategic brand
            positioning, tested and proven digital strategy to reach your ideal
            customers and gain a share of the market being target. We grow with
            you to drive business success.
          </p>
        </div>

        <div className="col-lg-4 col-md-4 px-0">
          <section className="services-image services-image-r service-image-switch">
            <img alt="Product marketing illustration" src={Product} />
          </section>
        </div>

        <img src={oox} className="oox" alt="aesthetics" style={{position:"absolute", top: "-50px", right: 40}} width="70px" height="126"/>
      </section>
      {/*END OF SERVICE WITH IMAGE TO THE RIGHT */}

      <section id="support-services">
        <div className="pss-tag mb-5">
          <h2>Professional and Support Services</h2>
          <div></div>
        </div>

        <article className="row mx-0">
          <section className="col-lg-4 col-md-6 pss-items">
            <div>
              <img
                src={webApp}
                alt="Web Application, Software Support & Maintenance"
              />
            </div>
            <h3>Web Application, Software Support & Maintenance</h3>
            <p className="mb-0">
              For enterprises with existing digital platforms (website, apps or
              software), we provide technical support to fix bugs, update the
              database. We do this on a one-off or retainer basis. Would it be
              right to say we are your inhouse tech team? Absolutely!
            </p>
          </section>

          <section className="col-lg-4 col-md-6 pss-items">
            <div>
              <img src={eCommerce} alt="Ecommerce Platform Management" />
            </div>
            <h3>Ecommerce Platform Management</h3>
            <p className="mb-0">
              To ensure e-commerce services fully optimise their platforms to
              drive sales, we provide constant support to update new products,
              improve users experience of the platform, fix bugs and optimise
              the website for increased conversion.
            </p>
          </section>

          <section className="col-lg-4 col-md-6 pss-items">
            <div>
              <img src={Consultancy} alt="Technology Consultancy" />
            </div>
            <h3>Technology Consultancy</h3>
            <p className="mb-0">
              We provide a detailed brainstorming session with you to understand
              your business and technology objectives while we recommend the
              perfect tech solution or strategy to leverage for maximum business
              impact.
            </p>
          </section>

          <section className="col-lg-4 col-md-6 pss-items">
            <div>
              <img src={GovProj} alt="Governmental Projects" />
            </div>
            <h3>Governmental Projects</h3>
            <p className="mb-0">
              We are a go-to partner for government parastatals and the public
              sector on development projects such as digitalisation. With our
              experience in digital transformation, we help in ensuring the
              smooth transitioning public sector systems and processes into a
              digital outlook.
            </p>
          </section>

          <section className="col-lg-4 col-md-6 pss-items">
            <div>
              <img
                src={notForProfit}
                alt="Not-for-Profit 
IT Support or Projects"
              />
            </div>
            <h3>Not-for-Profit IT Support or Projects</h3>
            <p className="mb-0">
              With our expertise in deploying technology solutions for
              institutions, we work side by side with Not-for-Profit
              organisations to drive tech inclusion in their processes, events,
              or developments projects.
            </p>
          </section>

          <section className="col-lg-4 col-md-6 pss-items">
            <div>
              <img src={security} alt="Security Audit" />
            </div>
            <h3>Security Audit</h3>
            <p className="mb-0"></p>
          </section>
        </article>
      </section>

      <section id="industry-served">
        {/* <div className="text-center mb-4">
          <h2>Industry Served</h2>
        </div> */}
        <article className="industry-served-grid">
          <div className="served-items">
            <section>
              <div className="text-center">
                <img alt="finance" src={finance} />
              </div>
              <p>Finance</p>
            </section>
          </div>

          <div className="served-items">
            <section>
              <div className="text-center">
                <img alt="communication" src={communication} />
              </div>
              <p>Communication</p>
            </section>
          </div>

          <div className="served-items">
            <section>
              <div className="text-center">
                <img alt="logistics" src={logistics} />
              </div>
              <p>Transportation & Logisitics</p>
            </section>
          </div>

          <div className="served-items">
            <section>
              <div className="text-center">
                <img alt="eCommerce" src={eCom} />
              </div>
              <p>Ecommerce</p>
            </section>
          </div>

          <div className="served-items">
            <section>
              <div className="text-center">
                <img alt="travel" src={travel} />
              </div>
              <p>Travels & Tourism</p>
            </section>
          </div>
        </article>
      </section>
    </div>
  );
};

export default OurServices;
