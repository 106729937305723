import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import Home from './pages/home/Home';
import Contact from './pages/contact/contact';
import Services from './pages/our-services/ourServices'
import About from './pages/about/about'
import Layout from './components/layout/Layout'


  const MainRoute = ({ Component, path, exact, purpose, auth, ...rest})=>{
    return(
      <Route exact={exact} path={path} {...rest} render={(props)=>{
        return(
          <Layout>
            <Component {...rest} {...props}/>
          </Layout>
        )
      }}/>
    )
  }


  function appRoute() {
    return (
      <Router>
        <Switch>
            <MainRoute path="/"  Component={Home} exact/>
            <MainRoute path="/contact" Component={Contact}/>
            <MainRoute path="/services" Component={Services}/>
            <MainRoute path="/about" Component={About}/>
          </Switch>
    </Router>
    )
  }

export default appRoute;