import React, { useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";

const Layout = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Header open={open} setOpen={setOpen} />
      <div className={open?"blur-out":""}><div className={open?"on-blur":""} onClick={()=>{setOpen(false)}}></div>{props.children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
