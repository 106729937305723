import './App.css';
import React from 'react'
import AppRoute from './AppRoute'



const App =()=> {
    return (
      <div className="App" data-test="component-app">
        <AppRoute/>
      </div>
    );
  }
  


export default App;
